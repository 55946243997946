import { default as affiliatesfyH5MT4X9sMeta } from "/codebuild/output/src3327976436/src/notionforms/client/pages/affiliates.vue?macro=true";
import { default as database_45viewsawwYvSerAyMeta } from "/codebuild/output/src3327976436/src/notionforms/client/pages/database-views.vue?macro=true";
import { default as discount_45students_45academics_45ngoszoEpXOOa2yMeta } from "/codebuild/output/src3327976436/src/notionforms/client/pages/discount-students-academics-ngos.vue?macro=true";
import { default as _91slug_93OY2Rqgi5G3Meta } from "/codebuild/output/src3327976436/src/notionforms/client/pages/features/[slug].vue?macro=true";
import { default as indexF4EvA3AVGNMeta } from "/codebuild/output/src3327976436/src/notionforms/client/pages/features/index.vue?macro=true";
import { default as _91slug_93mZ1HgyPTQbMeta } from "/codebuild/output/src3327976436/src/notionforms/client/pages/form-templates/[slug].vue?macro=true";
import { default as indexruTiUT7SN2Meta } from "/codebuild/output/src3327976436/src/notionforms/client/pages/form-templates/index.vue?macro=true";
import { default as _91slug_93vtK2EWXSmHMeta } from "/codebuild/output/src3327976436/src/notionforms/client/pages/form-templates/industries/[slug].vue?macro=true";
import { default as _91slug_93xYB4FybCQqMeta } from "/codebuild/output/src3327976436/src/notionforms/client/pages/form-templates/types/[slug].vue?macro=true";
import { default as editO5vxLCRLbTMeta } from "/codebuild/output/src3327976436/src/notionforms/client/pages/forms/[slug]/edit.vue?macro=true";
import { default as index0jqLj41iH8Meta } from "/codebuild/output/src3327976436/src/notionforms/client/pages/forms/[slug]/index.vue?macro=true";
import { default as analytics4qoQIS4jjpMeta } from "/codebuild/output/src3327976436/src/notionforms/client/pages/forms/[slug]/show/analytics.vue?macro=true";
import { default as indexJEAjLsJ6zRMeta } from "/codebuild/output/src3327976436/src/notionforms/client/pages/forms/[slug]/show/index.vue?macro=true";
import { default as indexvAaoKtVxMkMeta } from "/codebuild/output/src3327976436/src/notionforms/client/pages/forms/[slug]/show/integrations/index.vue?macro=true";
import { default as share65qF1lMFPJMeta } from "/codebuild/output/src3327976436/src/notionforms/client/pages/forms/[slug]/show/share.vue?macro=true";
import { default as showWSITWrdRBAMeta } from "/codebuild/output/src3327976436/src/notionforms/client/pages/forms/[slug]/show.vue?macro=true";
import { default as indexb3V9Uu3QlZMeta } from "/codebuild/output/src3327976436/src/notionforms/client/pages/forms/create/index.vue?macro=true";
import { default as uploadsmk03MTfBZRMeta } from "/codebuild/output/src3327976436/src/notionforms/client/pages/forms/uploads.vue?macro=true";
import { default as guidesDWHXQuUjKzMeta } from "/codebuild/output/src3327976436/src/notionforms/client/pages/guides.vue?macro=true";
import { default as homexlWddVoRD3Meta } from "/codebuild/output/src3327976436/src/notionforms/client/pages/home.vue?macro=true";
import { default as indexAoNtU9e8n8Meta } from "/codebuild/output/src3327976436/src/notionforms/client/pages/index.vue?macro=true";
import { default as integrationsb3SRfkQmcTMeta } from "/codebuild/output/src3327976436/src/notionforms/client/pages/integrations.vue?macro=true";
import { default as loginHByIFIDkMHMeta } from "/codebuild/output/src3327976436/src/notionforms/client/pages/login.vue?macro=true";
import { default as my_45templates4ozNcvFHzRMeta } from "/codebuild/output/src3327976436/src/notionforms/client/pages/my-templates.vue?macro=true";
import { default as notion_45ambassadors_45influencersWyunuO1JKsMeta } from "/codebuild/output/src3327976436/src/notionforms/client/pages/notion-ambassadors-influencers.vue?macro=true";
import { default as _91slug_93Waa8QmMSUqMeta } from "/codebuild/output/src3327976436/src/notionforms/client/pages/notion-form/[slug].vue?macro=true";
import { default as callbackJiDRxFxCCAMeta } from "/codebuild/output/src3327976436/src/notionforms/client/pages/notion/callback.vue?macro=true";
import { default as guest_45callbackTtnRb4e5pbMeta } from "/codebuild/output/src3327976436/src/notionforms/client/pages/notion/guest-callback.vue?macro=true";
import { default as callbackXYeRxQo43QMeta } from "/codebuild/output/src3327976436/src/notionforms/client/pages/oauth/callback.vue?macro=true";
import { default as partnersS1TwPYscTwMeta } from "/codebuild/output/src3327976436/src/notionforms/client/pages/partners.vue?macro=true";
import { default as _91token_93Ytj6xHci9TMeta } from "/codebuild/output/src3327976436/src/notionforms/client/pages/password/reset/[token].vue?macro=true";
import { default as indexvCuHfGHAdqMeta } from "/codebuild/output/src3327976436/src/notionforms/client/pages/password/reset/index.vue?macro=true";
import { default as pricingFJqz1LYp4IMeta } from "/codebuild/output/src3327976436/src/notionforms/client/pages/pricing.vue?macro=true";
import { default as privacy_45policyo2maSZGGGAMeta } from "/codebuild/output/src3327976436/src/notionforms/client/pages/privacy-policy.vue?macro=true";
import { default as registerncoTJGSjKoMeta } from "/codebuild/output/src3327976436/src/notionforms/client/pages/register.vue?macro=true";
import { default as access_45tokensLGSyCx0koUMeta } from "/codebuild/output/src3327976436/src/notionforms/client/pages/settings/access-tokens.vue?macro=true";
import { default as accountuUiYk4H5OgMeta } from "/codebuild/output/src3327976436/src/notionforms/client/pages/settings/account.vue?macro=true";
import { default as adminVcibNebRatMeta } from "/codebuild/output/src3327976436/src/notionforms/client/pages/settings/admin.vue?macro=true";
import { default as billingKMX01hfAkbMeta } from "/codebuild/output/src3327976436/src/notionforms/client/pages/settings/billing.vue?macro=true";
import { default as connectionsaeWvTMtz9QMeta } from "/codebuild/output/src3327976436/src/notionforms/client/pages/settings/connections.vue?macro=true";
import { default as custom_45domainf2KoPiGGu1Meta } from "/codebuild/output/src3327976436/src/notionforms/client/pages/settings/custom-domain.vue?macro=true";
import { default as email_45settingsM8x7W68iY6Meta } from "/codebuild/output/src3327976436/src/notionforms/client/pages/settings/email-settings.vue?macro=true";
import { default as indexBeYwsWrbe9Meta } from "/codebuild/output/src3327976436/src/notionforms/client/pages/settings/index.vue?macro=true";
import { default as passwordrmv1eZHuIVMeta } from "/codebuild/output/src3327976436/src/notionforms/client/pages/settings/password.vue?macro=true";
import { default as profileeZkDJvh2rTMeta } from "/codebuild/output/src3327976436/src/notionforms/client/pages/settings/profile.vue?macro=true";
import { default as workspacesKM2yuFF7W0Meta } from "/codebuild/output/src3327976436/src/notionforms/client/pages/settings/workspaces.vue?macro=true";
import { default as settingsNsJ1glzSiHMeta } from "/codebuild/output/src3327976436/src/notionforms/client/pages/settings.vue?macro=true";
import { default as errorxYNFpmP49QMeta } from "/codebuild/output/src3327976436/src/notionforms/client/pages/subscriptions/error.vue?macro=true";
import { default as successG1IKfiIIlCMeta } from "/codebuild/output/src3327976436/src/notionforms/client/pages/subscriptions/success.vue?macro=true";
import { default as terms_45conditions5X7xKbSq5pMeta } from "/codebuild/output/src3327976436/src/notionforms/client/pages/terms-conditions.vue?macro=true";
import { default as edit2dWjahcjN6Meta } from "/codebuild/output/src3327976436/src/notionforms/client/pages/views/[slug]/edit.vue?macro=true";
import { default as indexxpWuip0cQKMeta } from "/codebuild/output/src3327976436/src/notionforms/client/pages/views/[slug]/index.vue?macro=true";
import { default as index9mo5ooTUVPMeta } from "/codebuild/output/src3327976436/src/notionforms/client/pages/views/[slug]/show/index.vue?macro=true";
import { default as shareJLs95OpuX3Meta } from "/codebuild/output/src3327976436/src/notionforms/client/pages/views/[slug]/show/share.vue?macro=true";
import { default as showwJpr6ZgPKOMeta } from "/codebuild/output/src3327976436/src/notionforms/client/pages/views/[slug]/show.vue?macro=true";
import { default as indexh3z9919cQDMeta } from "/codebuild/output/src3327976436/src/notionforms/client/pages/views/create/index.vue?macro=true";
import { default as indexAlNRUTwQU6Meta } from "/codebuild/output/src3327976436/src/notionforms/client/pages/views/index.vue?macro=true";
import { default as uploadsB3B1qRlbc4Meta } from "/codebuild/output/src3327976436/src/notionforms/client/pages/views/uploads.vue?macro=true";
export default [
  {
    name: affiliatesfyH5MT4X9sMeta?.name ?? "affiliates",
    path: affiliatesfyH5MT4X9sMeta?.path ?? "/affiliates",
    meta: affiliatesfyH5MT4X9sMeta || {},
    alias: affiliatesfyH5MT4X9sMeta?.alias || [],
    redirect: affiliatesfyH5MT4X9sMeta?.redirect,
    component: () => import("/codebuild/output/src3327976436/src/notionforms/client/pages/affiliates.vue").then(m => m.default || m)
  },
  {
    name: database_45viewsawwYvSerAyMeta?.name ?? "database-views",
    path: database_45viewsawwYvSerAyMeta?.path ?? "/database-views",
    meta: database_45viewsawwYvSerAyMeta || {},
    alias: database_45viewsawwYvSerAyMeta?.alias || [],
    redirect: database_45viewsawwYvSerAyMeta?.redirect,
    component: () => import("/codebuild/output/src3327976436/src/notionforms/client/pages/database-views.vue").then(m => m.default || m)
  },
  {
    name: discount_45students_45academics_45ngoszoEpXOOa2yMeta?.name ?? "discount-students-academics-ngos",
    path: discount_45students_45academics_45ngoszoEpXOOa2yMeta?.path ?? "/discount-students-academics-ngos",
    meta: discount_45students_45academics_45ngoszoEpXOOa2yMeta || {},
    alias: discount_45students_45academics_45ngoszoEpXOOa2yMeta?.alias || [],
    redirect: discount_45students_45academics_45ngoszoEpXOOa2yMeta?.redirect,
    component: () => import("/codebuild/output/src3327976436/src/notionforms/client/pages/discount-students-academics-ngos.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93OY2Rqgi5G3Meta?.name ?? "features-slug",
    path: _91slug_93OY2Rqgi5G3Meta?.path ?? "/features/:slug()",
    meta: _91slug_93OY2Rqgi5G3Meta || {},
    alias: _91slug_93OY2Rqgi5G3Meta?.alias || [],
    redirect: _91slug_93OY2Rqgi5G3Meta?.redirect,
    component: () => import("/codebuild/output/src3327976436/src/notionforms/client/pages/features/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexF4EvA3AVGNMeta?.name ?? "features",
    path: indexF4EvA3AVGNMeta?.path ?? "/features",
    meta: indexF4EvA3AVGNMeta || {},
    alias: indexF4EvA3AVGNMeta?.alias || [],
    redirect: indexF4EvA3AVGNMeta?.redirect,
    component: () => import("/codebuild/output/src3327976436/src/notionforms/client/pages/features/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93mZ1HgyPTQbMeta?.name ?? "form-templates-slug",
    path: _91slug_93mZ1HgyPTQbMeta?.path ?? "/form-templates/:slug()",
    meta: _91slug_93mZ1HgyPTQbMeta || {},
    alias: _91slug_93mZ1HgyPTQbMeta?.alias || [],
    redirect: _91slug_93mZ1HgyPTQbMeta?.redirect,
    component: () => import("/codebuild/output/src3327976436/src/notionforms/client/pages/form-templates/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexruTiUT7SN2Meta?.name ?? "form-templates",
    path: indexruTiUT7SN2Meta?.path ?? "/form-templates",
    meta: indexruTiUT7SN2Meta || {},
    alias: indexruTiUT7SN2Meta?.alias || [],
    redirect: indexruTiUT7SN2Meta?.redirect,
    component: () => import("/codebuild/output/src3327976436/src/notionforms/client/pages/form-templates/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vtK2EWXSmHMeta?.name ?? "form-templates-industries-slug",
    path: _91slug_93vtK2EWXSmHMeta?.path ?? "/form-templates/industries/:slug()",
    meta: _91slug_93vtK2EWXSmHMeta || {},
    alias: _91slug_93vtK2EWXSmHMeta?.alias || [],
    redirect: _91slug_93vtK2EWXSmHMeta?.redirect,
    component: () => import("/codebuild/output/src3327976436/src/notionforms/client/pages/form-templates/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93xYB4FybCQqMeta?.name ?? "form-templates-types-slug",
    path: _91slug_93xYB4FybCQqMeta?.path ?? "/form-templates/types/:slug()",
    meta: _91slug_93xYB4FybCQqMeta || {},
    alias: _91slug_93xYB4FybCQqMeta?.alias || [],
    redirect: _91slug_93xYB4FybCQqMeta?.redirect,
    component: () => import("/codebuild/output/src3327976436/src/notionforms/client/pages/form-templates/types/[slug].vue").then(m => m.default || m)
  },
  {
    name: editO5vxLCRLbTMeta?.name ?? "forms-slug-edit",
    path: editO5vxLCRLbTMeta?.path ?? "/forms/:slug()/edit",
    meta: editO5vxLCRLbTMeta || {},
    alias: editO5vxLCRLbTMeta?.alias || [],
    redirect: editO5vxLCRLbTMeta?.redirect,
    component: () => import("/codebuild/output/src3327976436/src/notionforms/client/pages/forms/[slug]/edit.vue").then(m => m.default || m)
  },
  {
    name: index0jqLj41iH8Meta?.name ?? "forms-slug",
    path: index0jqLj41iH8Meta?.path ?? "/forms/:slug()",
    meta: index0jqLj41iH8Meta || {},
    alias: index0jqLj41iH8Meta?.alias || [],
    redirect: index0jqLj41iH8Meta?.redirect,
    component: () => import("/codebuild/output/src3327976436/src/notionforms/client/pages/forms/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: showWSITWrdRBAMeta?.name ?? undefined,
    path: showWSITWrdRBAMeta?.path ?? "/forms/:slug()/show",
    meta: showWSITWrdRBAMeta || {},
    alias: showWSITWrdRBAMeta?.alias || [],
    redirect: showWSITWrdRBAMeta?.redirect,
    component: () => import("/codebuild/output/src3327976436/src/notionforms/client/pages/forms/[slug]/show.vue").then(m => m.default || m),
    children: [
  {
    name: analytics4qoQIS4jjpMeta?.name ?? "forms-slug-show-analytics",
    path: analytics4qoQIS4jjpMeta?.path ?? "analytics",
    meta: analytics4qoQIS4jjpMeta || {},
    alias: analytics4qoQIS4jjpMeta?.alias || [],
    redirect: analytics4qoQIS4jjpMeta?.redirect,
    component: () => import("/codebuild/output/src3327976436/src/notionforms/client/pages/forms/[slug]/show/analytics.vue").then(m => m.default || m)
  },
  {
    name: indexJEAjLsJ6zRMeta?.name ?? "forms-slug-show",
    path: indexJEAjLsJ6zRMeta?.path ?? "",
    meta: indexJEAjLsJ6zRMeta || {},
    alias: indexJEAjLsJ6zRMeta?.alias || [],
    redirect: indexJEAjLsJ6zRMeta?.redirect,
    component: () => import("/codebuild/output/src3327976436/src/notionforms/client/pages/forms/[slug]/show/index.vue").then(m => m.default || m)
  },
  {
    name: indexvAaoKtVxMkMeta?.name ?? "forms-slug-show-integrations",
    path: indexvAaoKtVxMkMeta?.path ?? "integrations",
    meta: indexvAaoKtVxMkMeta || {},
    alias: indexvAaoKtVxMkMeta?.alias || [],
    redirect: indexvAaoKtVxMkMeta?.redirect,
    component: () => import("/codebuild/output/src3327976436/src/notionforms/client/pages/forms/[slug]/show/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: share65qF1lMFPJMeta?.name ?? "forms-slug-show-share",
    path: share65qF1lMFPJMeta?.path ?? "share",
    meta: share65qF1lMFPJMeta || {},
    alias: share65qF1lMFPJMeta?.alias || [],
    redirect: share65qF1lMFPJMeta?.redirect,
    component: () => import("/codebuild/output/src3327976436/src/notionforms/client/pages/forms/[slug]/show/share.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexb3V9Uu3QlZMeta?.name ?? "forms-create",
    path: indexb3V9Uu3QlZMeta?.path ?? "/forms/create",
    meta: indexb3V9Uu3QlZMeta || {},
    alias: indexb3V9Uu3QlZMeta?.alias || [],
    redirect: indexb3V9Uu3QlZMeta?.redirect,
    component: () => import("/codebuild/output/src3327976436/src/notionforms/client/pages/forms/create/index.vue").then(m => m.default || m)
  },
  {
    name: uploadsmk03MTfBZRMeta?.name ?? "forms-uploads",
    path: uploadsmk03MTfBZRMeta?.path ?? "/forms/uploads",
    meta: uploadsmk03MTfBZRMeta || {},
    alias: uploadsmk03MTfBZRMeta?.alias || [],
    redirect: uploadsmk03MTfBZRMeta?.redirect,
    component: () => import("/codebuild/output/src3327976436/src/notionforms/client/pages/forms/uploads.vue").then(m => m.default || m)
  },
  {
    name: guidesDWHXQuUjKzMeta?.name ?? "guides",
    path: guidesDWHXQuUjKzMeta?.path ?? "/guides",
    meta: guidesDWHXQuUjKzMeta || {},
    alias: guidesDWHXQuUjKzMeta?.alias || [],
    redirect: guidesDWHXQuUjKzMeta?.redirect,
    component: () => import("/codebuild/output/src3327976436/src/notionforms/client/pages/guides.vue").then(m => m.default || m)
  },
  {
    name: homexlWddVoRD3Meta?.name ?? "home",
    path: homexlWddVoRD3Meta?.path ?? "/home",
    meta: homexlWddVoRD3Meta || {},
    alias: homexlWddVoRD3Meta?.alias || [],
    redirect: homexlWddVoRD3Meta?.redirect,
    component: () => import("/codebuild/output/src3327976436/src/notionforms/client/pages/home.vue").then(m => m.default || m)
  },
  {
    name: indexAoNtU9e8n8Meta?.name ?? "index",
    path: indexAoNtU9e8n8Meta?.path ?? "/",
    meta: indexAoNtU9e8n8Meta || {},
    alias: indexAoNtU9e8n8Meta?.alias || [],
    redirect: indexAoNtU9e8n8Meta?.redirect,
    component: () => import("/codebuild/output/src3327976436/src/notionforms/client/pages/index.vue").then(m => m.default || m)
  },
  {
    name: integrationsb3SRfkQmcTMeta?.name ?? "integrations",
    path: integrationsb3SRfkQmcTMeta?.path ?? "/integrations",
    meta: integrationsb3SRfkQmcTMeta || {},
    alias: integrationsb3SRfkQmcTMeta?.alias || [],
    redirect: integrationsb3SRfkQmcTMeta?.redirect,
    component: () => import("/codebuild/output/src3327976436/src/notionforms/client/pages/integrations.vue").then(m => m.default || m)
  },
  {
    name: loginHByIFIDkMHMeta?.name ?? "login",
    path: loginHByIFIDkMHMeta?.path ?? "/login",
    meta: loginHByIFIDkMHMeta || {},
    alias: loginHByIFIDkMHMeta?.alias || [],
    redirect: loginHByIFIDkMHMeta?.redirect,
    component: () => import("/codebuild/output/src3327976436/src/notionforms/client/pages/login.vue").then(m => m.default || m)
  },
  {
    name: my_45templates4ozNcvFHzRMeta?.name ?? "my-templates",
    path: my_45templates4ozNcvFHzRMeta?.path ?? "/my-templates",
    meta: my_45templates4ozNcvFHzRMeta || {},
    alias: my_45templates4ozNcvFHzRMeta?.alias || [],
    redirect: my_45templates4ozNcvFHzRMeta?.redirect,
    component: () => import("/codebuild/output/src3327976436/src/notionforms/client/pages/my-templates.vue").then(m => m.default || m)
  },
  {
    name: notion_45ambassadors_45influencersWyunuO1JKsMeta?.name ?? "notion-ambassadors-influencers",
    path: notion_45ambassadors_45influencersWyunuO1JKsMeta?.path ?? "/notion-ambassadors-influencers",
    meta: notion_45ambassadors_45influencersWyunuO1JKsMeta || {},
    alias: notion_45ambassadors_45influencersWyunuO1JKsMeta?.alias || [],
    redirect: notion_45ambassadors_45influencersWyunuO1JKsMeta?.redirect,
    component: () => import("/codebuild/output/src3327976436/src/notionforms/client/pages/notion-ambassadors-influencers.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Waa8QmMSUqMeta?.name ?? "notion-form-slug",
    path: _91slug_93Waa8QmMSUqMeta?.path ?? "/notion-form/:slug()",
    meta: _91slug_93Waa8QmMSUqMeta || {},
    alias: _91slug_93Waa8QmMSUqMeta?.alias || [],
    redirect: _91slug_93Waa8QmMSUqMeta?.redirect,
    component: () => import("/codebuild/output/src3327976436/src/notionforms/client/pages/notion-form/[slug].vue").then(m => m.default || m)
  },
  {
    name: callbackJiDRxFxCCAMeta?.name ?? "notion-callback",
    path: callbackJiDRxFxCCAMeta?.path ?? "/notion/callback",
    meta: callbackJiDRxFxCCAMeta || {},
    alias: callbackJiDRxFxCCAMeta?.alias || [],
    redirect: callbackJiDRxFxCCAMeta?.redirect,
    component: () => import("/codebuild/output/src3327976436/src/notionforms/client/pages/notion/callback.vue").then(m => m.default || m)
  },
  {
    name: guest_45callbackTtnRb4e5pbMeta?.name ?? "notion-guest-callback",
    path: guest_45callbackTtnRb4e5pbMeta?.path ?? "/notion/guest-callback",
    meta: guest_45callbackTtnRb4e5pbMeta || {},
    alias: guest_45callbackTtnRb4e5pbMeta?.alias || [],
    redirect: guest_45callbackTtnRb4e5pbMeta?.redirect,
    component: () => import("/codebuild/output/src3327976436/src/notionforms/client/pages/notion/guest-callback.vue").then(m => m.default || m)
  },
  {
    name: callbackXYeRxQo43QMeta?.name ?? "oauth-callback",
    path: callbackXYeRxQo43QMeta?.path ?? "/oauth/callback",
    meta: callbackXYeRxQo43QMeta || {},
    alias: callbackXYeRxQo43QMeta?.alias || [],
    redirect: callbackXYeRxQo43QMeta?.redirect,
    component: () => import("/codebuild/output/src3327976436/src/notionforms/client/pages/oauth/callback.vue").then(m => m.default || m)
  },
  {
    name: partnersS1TwPYscTwMeta?.name ?? "partners",
    path: partnersS1TwPYscTwMeta?.path ?? "/partners",
    meta: partnersS1TwPYscTwMeta || {},
    alias: partnersS1TwPYscTwMeta?.alias || [],
    redirect: partnersS1TwPYscTwMeta?.redirect,
    component: () => import("/codebuild/output/src3327976436/src/notionforms/client/pages/partners.vue").then(m => m.default || m)
  },
  {
    name: _91token_93Ytj6xHci9TMeta?.name ?? "password-reset-token",
    path: _91token_93Ytj6xHci9TMeta?.path ?? "/password/reset/:token()",
    meta: _91token_93Ytj6xHci9TMeta || {},
    alias: _91token_93Ytj6xHci9TMeta?.alias || [],
    redirect: _91token_93Ytj6xHci9TMeta?.redirect,
    component: () => import("/codebuild/output/src3327976436/src/notionforms/client/pages/password/reset/[token].vue").then(m => m.default || m)
  },
  {
    name: indexvCuHfGHAdqMeta?.name ?? "password-reset",
    path: indexvCuHfGHAdqMeta?.path ?? "/password/reset",
    meta: indexvCuHfGHAdqMeta || {},
    alias: indexvCuHfGHAdqMeta?.alias || [],
    redirect: indexvCuHfGHAdqMeta?.redirect,
    component: () => import("/codebuild/output/src3327976436/src/notionforms/client/pages/password/reset/index.vue").then(m => m.default || m)
  },
  {
    name: pricingFJqz1LYp4IMeta?.name ?? "pricing",
    path: pricingFJqz1LYp4IMeta?.path ?? "/pricing",
    meta: pricingFJqz1LYp4IMeta || {},
    alias: pricingFJqz1LYp4IMeta?.alias || [],
    redirect: pricingFJqz1LYp4IMeta?.redirect,
    component: () => import("/codebuild/output/src3327976436/src/notionforms/client/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyo2maSZGGGAMeta?.name ?? "privacy-policy",
    path: privacy_45policyo2maSZGGGAMeta?.path ?? "/privacy-policy",
    meta: privacy_45policyo2maSZGGGAMeta || {},
    alias: privacy_45policyo2maSZGGGAMeta?.alias || [],
    redirect: privacy_45policyo2maSZGGGAMeta?.redirect,
    component: () => import("/codebuild/output/src3327976436/src/notionforms/client/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: registerncoTJGSjKoMeta?.name ?? "register",
    path: registerncoTJGSjKoMeta?.path ?? "/register",
    meta: registerncoTJGSjKoMeta || {},
    alias: registerncoTJGSjKoMeta?.alias || [],
    redirect: registerncoTJGSjKoMeta?.redirect,
    component: () => import("/codebuild/output/src3327976436/src/notionforms/client/pages/register.vue").then(m => m.default || m)
  },
  {
    name: settingsNsJ1glzSiHMeta?.name ?? undefined,
    path: settingsNsJ1glzSiHMeta?.path ?? "/settings",
    meta: settingsNsJ1glzSiHMeta || {},
    alias: settingsNsJ1glzSiHMeta?.alias || [],
    redirect: settingsNsJ1glzSiHMeta?.redirect,
    component: () => import("/codebuild/output/src3327976436/src/notionforms/client/pages/settings.vue").then(m => m.default || m),
    children: [
  {
    name: access_45tokensLGSyCx0koUMeta?.name ?? "settings-access-tokens",
    path: access_45tokensLGSyCx0koUMeta?.path ?? "access-tokens",
    meta: access_45tokensLGSyCx0koUMeta || {},
    alias: access_45tokensLGSyCx0koUMeta?.alias || [],
    redirect: access_45tokensLGSyCx0koUMeta?.redirect,
    component: () => import("/codebuild/output/src3327976436/src/notionforms/client/pages/settings/access-tokens.vue").then(m => m.default || m)
  },
  {
    name: accountuUiYk4H5OgMeta?.name ?? "settings-account",
    path: accountuUiYk4H5OgMeta?.path ?? "account",
    meta: accountuUiYk4H5OgMeta || {},
    alias: accountuUiYk4H5OgMeta?.alias || [],
    redirect: accountuUiYk4H5OgMeta?.redirect,
    component: () => import("/codebuild/output/src3327976436/src/notionforms/client/pages/settings/account.vue").then(m => m.default || m)
  },
  {
    name: adminVcibNebRatMeta?.name ?? "settings-admin",
    path: adminVcibNebRatMeta?.path ?? "admin",
    meta: adminVcibNebRatMeta || {},
    alias: adminVcibNebRatMeta?.alias || [],
    redirect: adminVcibNebRatMeta?.redirect,
    component: () => import("/codebuild/output/src3327976436/src/notionforms/client/pages/settings/admin.vue").then(m => m.default || m)
  },
  {
    name: billingKMX01hfAkbMeta?.name ?? "settings-billing",
    path: billingKMX01hfAkbMeta?.path ?? "billing",
    meta: billingKMX01hfAkbMeta || {},
    alias: billingKMX01hfAkbMeta?.alias || [],
    redirect: billingKMX01hfAkbMeta?.redirect,
    component: () => import("/codebuild/output/src3327976436/src/notionforms/client/pages/settings/billing.vue").then(m => m.default || m)
  },
  {
    name: connectionsaeWvTMtz9QMeta?.name ?? "settings-connections",
    path: connectionsaeWvTMtz9QMeta?.path ?? "connections",
    meta: connectionsaeWvTMtz9QMeta || {},
    alias: connectionsaeWvTMtz9QMeta?.alias || [],
    redirect: connectionsaeWvTMtz9QMeta?.redirect,
    component: () => import("/codebuild/output/src3327976436/src/notionforms/client/pages/settings/connections.vue").then(m => m.default || m)
  },
  {
    name: custom_45domainf2KoPiGGu1Meta?.name ?? "settings-custom-domain",
    path: custom_45domainf2KoPiGGu1Meta?.path ?? "custom-domain",
    meta: custom_45domainf2KoPiGGu1Meta || {},
    alias: custom_45domainf2KoPiGGu1Meta?.alias || [],
    redirect: custom_45domainf2KoPiGGu1Meta?.redirect,
    component: () => import("/codebuild/output/src3327976436/src/notionforms/client/pages/settings/custom-domain.vue").then(m => m.default || m)
  },
  {
    name: email_45settingsM8x7W68iY6Meta?.name ?? "settings-email-settings",
    path: email_45settingsM8x7W68iY6Meta?.path ?? "email-settings",
    meta: email_45settingsM8x7W68iY6Meta || {},
    alias: email_45settingsM8x7W68iY6Meta?.alias || [],
    redirect: email_45settingsM8x7W68iY6Meta?.redirect,
    component: () => import("/codebuild/output/src3327976436/src/notionforms/client/pages/settings/email-settings.vue").then(m => m.default || m)
  },
  {
    name: indexBeYwsWrbe9Meta?.name ?? "settings",
    path: indexBeYwsWrbe9Meta?.path ?? "",
    meta: indexBeYwsWrbe9Meta || {},
    alias: indexBeYwsWrbe9Meta?.alias || [],
    redirect: indexBeYwsWrbe9Meta?.redirect,
    component: () => import("/codebuild/output/src3327976436/src/notionforms/client/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: passwordrmv1eZHuIVMeta?.name ?? "settings-password",
    path: passwordrmv1eZHuIVMeta?.path ?? "password",
    meta: passwordrmv1eZHuIVMeta || {},
    alias: passwordrmv1eZHuIVMeta?.alias || [],
    redirect: passwordrmv1eZHuIVMeta?.redirect,
    component: () => import("/codebuild/output/src3327976436/src/notionforms/client/pages/settings/password.vue").then(m => m.default || m)
  },
  {
    name: profileeZkDJvh2rTMeta?.name ?? "settings-profile",
    path: profileeZkDJvh2rTMeta?.path ?? "profile",
    meta: profileeZkDJvh2rTMeta || {},
    alias: profileeZkDJvh2rTMeta?.alias || [],
    redirect: profileeZkDJvh2rTMeta?.redirect,
    component: () => import("/codebuild/output/src3327976436/src/notionforms/client/pages/settings/profile.vue").then(m => m.default || m)
  },
  {
    name: workspacesKM2yuFF7W0Meta?.name ?? "settings-workspaces",
    path: workspacesKM2yuFF7W0Meta?.path ?? "workspaces",
    meta: workspacesKM2yuFF7W0Meta || {},
    alias: workspacesKM2yuFF7W0Meta?.alias || [],
    redirect: workspacesKM2yuFF7W0Meta?.redirect,
    component: () => import("/codebuild/output/src3327976436/src/notionforms/client/pages/settings/workspaces.vue").then(m => m.default || m)
  }
]
  },
  {
    name: errorxYNFpmP49QMeta?.name ?? "subscriptions-error",
    path: errorxYNFpmP49QMeta?.path ?? "/subscriptions/error",
    meta: errorxYNFpmP49QMeta || {},
    alias: errorxYNFpmP49QMeta?.alias || [],
    redirect: errorxYNFpmP49QMeta?.redirect,
    component: () => import("/codebuild/output/src3327976436/src/notionforms/client/pages/subscriptions/error.vue").then(m => m.default || m)
  },
  {
    name: successG1IKfiIIlCMeta?.name ?? "subscriptions-success",
    path: successG1IKfiIIlCMeta?.path ?? "/subscriptions/success",
    meta: successG1IKfiIIlCMeta || {},
    alias: successG1IKfiIIlCMeta?.alias || [],
    redirect: successG1IKfiIIlCMeta?.redirect,
    component: () => import("/codebuild/output/src3327976436/src/notionforms/client/pages/subscriptions/success.vue").then(m => m.default || m)
  },
  {
    name: terms_45conditions5X7xKbSq5pMeta?.name ?? "terms-conditions",
    path: terms_45conditions5X7xKbSq5pMeta?.path ?? "/terms-conditions",
    meta: terms_45conditions5X7xKbSq5pMeta || {},
    alias: terms_45conditions5X7xKbSq5pMeta?.alias || [],
    redirect: terms_45conditions5X7xKbSq5pMeta?.redirect,
    component: () => import("/codebuild/output/src3327976436/src/notionforms/client/pages/terms-conditions.vue").then(m => m.default || m)
  },
  {
    name: edit2dWjahcjN6Meta?.name ?? "views-slug-edit",
    path: edit2dWjahcjN6Meta?.path ?? "/views/:slug()/edit",
    meta: edit2dWjahcjN6Meta || {},
    alias: edit2dWjahcjN6Meta?.alias || [],
    redirect: edit2dWjahcjN6Meta?.redirect,
    component: () => import("/codebuild/output/src3327976436/src/notionforms/client/pages/views/[slug]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexxpWuip0cQKMeta?.name ?? "views-slug",
    path: indexxpWuip0cQKMeta?.path ?? "/views/:slug()",
    meta: indexxpWuip0cQKMeta || {},
    alias: indexxpWuip0cQKMeta?.alias || [],
    redirect: indexxpWuip0cQKMeta?.redirect,
    component: () => import("/codebuild/output/src3327976436/src/notionforms/client/pages/views/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: showwJpr6ZgPKOMeta?.name ?? undefined,
    path: showwJpr6ZgPKOMeta?.path ?? "/views/:slug()/show",
    meta: showwJpr6ZgPKOMeta || {},
    alias: showwJpr6ZgPKOMeta?.alias || [],
    redirect: showwJpr6ZgPKOMeta?.redirect,
    component: () => import("/codebuild/output/src3327976436/src/notionforms/client/pages/views/[slug]/show.vue").then(m => m.default || m),
    children: [
  {
    name: index9mo5ooTUVPMeta?.name ?? "views-slug-show",
    path: index9mo5ooTUVPMeta?.path ?? "",
    meta: index9mo5ooTUVPMeta || {},
    alias: index9mo5ooTUVPMeta?.alias || [],
    redirect: index9mo5ooTUVPMeta?.redirect,
    component: () => import("/codebuild/output/src3327976436/src/notionforms/client/pages/views/[slug]/show/index.vue").then(m => m.default || m)
  },
  {
    name: shareJLs95OpuX3Meta?.name ?? "views-slug-show-share",
    path: shareJLs95OpuX3Meta?.path ?? "share",
    meta: shareJLs95OpuX3Meta || {},
    alias: shareJLs95OpuX3Meta?.alias || [],
    redirect: shareJLs95OpuX3Meta?.redirect,
    component: () => import("/codebuild/output/src3327976436/src/notionforms/client/pages/views/[slug]/show/share.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexh3z9919cQDMeta?.name ?? "views-create",
    path: indexh3z9919cQDMeta?.path ?? "/views/create",
    meta: indexh3z9919cQDMeta || {},
    alias: indexh3z9919cQDMeta?.alias || [],
    redirect: indexh3z9919cQDMeta?.redirect,
    component: () => import("/codebuild/output/src3327976436/src/notionforms/client/pages/views/create/index.vue").then(m => m.default || m)
  },
  {
    name: indexAlNRUTwQU6Meta?.name ?? "views",
    path: indexAlNRUTwQU6Meta?.path ?? "/views",
    meta: indexAlNRUTwQU6Meta || {},
    alias: indexAlNRUTwQU6Meta?.alias || [],
    redirect: indexAlNRUTwQU6Meta?.redirect,
    component: () => import("/codebuild/output/src3327976436/src/notionforms/client/pages/views/index.vue").then(m => m.default || m)
  },
  {
    name: uploadsB3B1qRlbc4Meta?.name ?? "views-uploads",
    path: uploadsB3B1qRlbc4Meta?.path ?? "/views/uploads",
    meta: uploadsB3B1qRlbc4Meta || {},
    alias: uploadsB3B1qRlbc4Meta?.alias || [],
    redirect: uploadsB3B1qRlbc4Meta?.redirect,
    component: () => import("/codebuild/output/src3327976436/src/notionforms/client/pages/views/uploads.vue").then(m => m.default || m)
  }
]