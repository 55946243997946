import revive_payload_client_4sVQNw7RlN from "/codebuild/output/src3327976436/src/notionforms/client/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/codebuild/output/src3327976436/src/notionforms/client/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/codebuild/output/src3327976436/src/notionforms/client/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_7pzUtwM1Zj from "/codebuild/output/src3327976436/src/notionforms/client/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import payload_client_yVLowv6hDl from "/codebuild/output/src3327976436/src/notionforms/client/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/codebuild/output/src3327976436/src/notionforms/client/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/codebuild/output/src3327976436/src/notionforms/client/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/codebuild/output/src3327976436/src/notionforms/client/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/codebuild/output/src3327976436/src/notionforms/client/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_3G0UZgWcDU from "/codebuild/output/src3327976436/src/notionforms/client/node_modules/nuxt-utm/dist/runtime/plugin.mjs";
import plugin_tbFNToZNim from "/codebuild/output/src3327976436/src/notionforms/client/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import motion_fmZWau4t0U from "/codebuild/output/src3327976436/src/notionforms/client/node_modules/@vueuse/motion/dist/runtime/templates/motion.mjs";
import slideovers_LDumGYo2KH from "/codebuild/output/src3327976436/src/notionforms/client/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.mjs";
import modals_bidRKewKK5 from "/codebuild/output/src3327976436/src/notionforms/client/node_modules/@nuxt/ui/dist/runtime/plugins/modals.mjs";
import colors_244lXBzhnM from "/codebuild/output/src3327976436/src/notionforms/client/node_modules/@nuxt/ui/dist/runtime/plugins/colors.mjs";
import plugin_client_i8AMfKeYnY from "/codebuild/output/src3327976436/src/notionforms/client/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import chunk_reload_client_UciE0i6zes from "/codebuild/output/src3327976436/src/notionforms/client/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import amplitude_EZuIopuxgB from "/codebuild/output/src3327976436/src/notionforms/client/plugins/amplitude.js";
import crisp_client_amQNA6tQA4 from "/codebuild/output/src3327976436/src/notionforms/client/plugins/crisp.client.js";
import pinia_history_Fm3e3342Dv from "/codebuild/output/src3327976436/src/notionforms/client/plugins/pinia-history.js";
import sentry_PZVGm6LH3G from "/codebuild/output/src3327976436/src/notionforms/client/plugins/sentry.js";
import vue_confetti_client_S5fochbKaT from "/codebuild/output/src3327976436/src/notionforms/client/plugins/vue-confetti.client.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_7pzUtwM1Zj,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_3G0UZgWcDU,
  plugin_tbFNToZNim,
  motion_fmZWau4t0U,
  slideovers_LDumGYo2KH,
  modals_bidRKewKK5,
  colors_244lXBzhnM,
  plugin_client_i8AMfKeYnY,
  chunk_reload_client_UciE0i6zes,
  amplitude_EZuIopuxgB,
  crisp_client_amQNA6tQA4,
  pinia_history_Fm3e3342Dv,
  sentry_PZVGm6LH3G,
  vue_confetti_client_S5fochbKaT
]